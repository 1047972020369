import LogRocket from 'logrocket';
import {isStage, mobileCheck} from './src/utils';

import './src/global.css';

mobileCheck();

const isHeadlessChrome = () => {
  const userAgent = navigator.userAgent;

  return userAgent.includes('HeadlessChrome');
}

if(!isStage && !isHeadlessChrome()) {
  LogRocket.init('8lugdu/gil-landing')
}
